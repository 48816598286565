import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsitePageService {


    paginate(slug, type = null, flag = null, index = null, ) {
        let url = API_URL + 'user/website/' + slug + '/page';
        if (index != null)
            url = url + '?page=' + index
        if (type != null)
            url = url + '?type=' + type
        if (flag != null)
            url = url + '?flag=' + flag
        return apiService.get(url);
    }

    create(slug, data) {
        let url = API_URL + 'user/website/' + slug + '/page'
        return apiService.post(url, data);
    }
    clone(slug,id) {
        let url = API_URL + 'user/website/' + slug + '/page/' +id +'/clone'
        return apiService.get(url);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/website/' + slug + '/page/' + id + '/edit'
        return apiService.post(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/page/' + id
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/page/' + id
        return apiService.get(url);
    }
    getPageBySlug(websiteId,slug){
        // console.log(websiteId)
        let url = API_URL + 'user/website/' + websiteId + '/page/' + slug +'/check-slug'
        return apiService.get(url);
    }

    recoverPage(slug, id){
        let url = API_URL + 'user/website/' + slug + '/page/' + id+'/recover'
        return apiService.get(url);
    }

    deletePermanently(slug, id){
        let url = API_URL + 'user/website/' + slug + '/page/' + id+'/delete/permanently'
        return apiService.get(url);
    }

    setBuilderRoute(domain,pageId){
        let url = API_URL + 'user/website/initiate-builder/' + domain + '/' + pageId
        return apiService.get(url);
    }

    reImportPages(domain){
        let url = API_URL + 'user/website/' + domain + '/pages/re-import';
        return apiService.get(url);
    }
}
